import React from "react";
import { Link, graphql } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import Jumbotron from "../components/pohrby/Jumbotron";
import Poplatky from "../components/pohrby/Poplatky";
import Img from "gatsby-image";


import LinkButton from "../components/pohrby/LinkButton";
import CoUdelame from "../components/pohrby/CoProVasUdelame";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import SupportPanel from "../components/SupportPanel";
import PoSvem from "../components/pohrby/PoSvem";
import UvodniText from "../components/pohrby/UvodniText";
import Features from "../components/pohrby/Features";
import Naceneni from "../components/pohrby/Naceneni";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import Press from "../components/pohrby/Press";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Postup from "../components/pohrby/Postup";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import NaceneniV2 from "../components/brandingV2/NaceneniV2";
import RegionsV2 from "../components/brandingV2/RegionsV2";


const faqData = [
  {
    otazka: "Mohu si napsat vlastní smuteční řeč?",
    odpoved: 'Ano, můžete. Doporučujeme však smuteční řeč konzultovat s pravoslavným knězem, který bude obřad vést. Můžete mu poskytnout vlastní poznámky a informace o zesnulém, které chcete, aby zmínil. Pravoslavný pohřební obřad má svou specifickou strukturu, ale osobní vzpomínky mohou být citlivě začleněny.'
  },
  {
    otazka: "Kdo pronáší smuteční řeč při pravoslavném pohřbu?",
    odpoved: 'Při pravoslavném pohřbu obvykle pronáší hlavní část obřadu kněz. Nicméně po domluvě s knězem je možné, aby krátkou vzpomínku pronesl i někdo z pozůstalých. Pravoslavná tradice klade důraz na modlitby za zesnulého.'
  },
  {
    otazka: "Kde se koná pravoslavný pohřební obřad a kolik lidí se ho může zúčastnit?",
    odpoved: "Pravoslavné pohřební obřady se mohou konat v pravoslavných chrámech, kaplích nebo na hřbitovech v různých městech ČR. Kapacita se liší podle konkrétního místa. Pro přesné informace o možnostech ve vašem regionu nás prosím kontaktujte. Zajistíme vhodné místo pro důstojný pohřeb."
  },
  {
    otazka: "Jak dlouho trvá pravoslavný pohřební obřad?",
    odpoved: "Pravoslavný pohřební obřad je obvykle delší než běžný civilní obřad. Může trvat až hodinu, někdy i déle. Zahrnuje čtení z Písma, modlitby, zpěvy a další tradiční prvky pravoslavné liturgie. Přesná délka závisí na konkrétních přáních rodiny a domluvě s knězem."
  },
  {
    otazka: "Jaká hudba se používá při pravoslavném pohřbu?",
    odpoved: "Tradičně se používají liturgické zpěvy a modlitby. Tyto jsou obvykle zpívány sborem nebo knězem. Po domluvě s knězem je možné zařadit i jiné vhodné duchovní skladby. Pravoslavná tradice klade důraz na duchovní rozměr hudby při obřadu."
  },
  {
    otazka: "Co všechno zahrnuje cena pravoslavného pohřbu?",
    odpoved: `Cena zahrnuje vyzvednutí zesnulého, úpravu těla a uložení do rakve v souladu s pravoslavnými tradicemi. Dále zajištění pravoslavného kněze, přípravu obřadního místa včetně pravoslavných symbolů a svící, tvorbu parte. Zajistíme i hrobové místo. Vše přizpůsobíme vašim zvyklostem a přáním.`
  },
  {
    otazka: "Kdy vás můžu kontaktovat ohledně pravoslavného pohřbu?",
    odpoved: "Jsme vám k dispozici nepřetržitě na čísle 315 558 136. Chápeme, že organizace pravoslavného pohřbu může být náročná, proto jsme připraveni vám pomoci kdykoliv. Poradíme s tradicemi, zajistíme pravoslavného kněze a vše potřebné pro důstojný obřad."
  },
  {
    otazka: "Jaké specifické prvky obsahuje pravoslavný pohřeb?",
    odpoved: "Pravoslavný pohřeb zahrnuje několik specifických prvků. Patří mezi ně například čtení žalmů u rakve před obřadem, používání kadidla, svíce v rukou účastníků, speciální modlitby za odpuštění hříchů zesnulého, a symbolické rozloučení s tělem. Vše zajistíme v souladu s pravoslavnými tradicemi a přáními rodiny."
  },
  {
    otazka: "Jak probíhá pravoslavný pohřeb do země?",
    odpoved: `Pravoslavný pohřeb do země obvykle zahrnuje obřad v chrámu nebo kapli, následovaný průvodem na hřbitov. Tam probíhá závěrečná část obřadu včetně posledního rozloučení a uložení do hrobu. Zajistíme vše potřebné včetně koordinace s hřbitovní správou a přípravy hrobového místa podle pravoslavných tradic.`
  },
  {
    otazka: "Můžete zajistit pravoslavného kněze pro pohřební obřad?",
    odpoved: "Ano, jsme schopni zajistit pravoslavného kněze pro vedení pohřebního obřadu. Spolupracujeme s duchovními v různých regionech ČR. Pomůžeme vám s komunikací s knězem ohledně specifických přání a detailů obřadu."
  },
  {
    otazka: "Jak se liší pravoslavný pohřeb od katolického nebo protestantského?",
    odpoved: "Pravoslavný pohřeb má své specifické rysy. Zahrnuje více symbolických úkonů, jako je používání kadidla a svící. Obřad je obvykle delší a obsahuje více čtení z Písma a modliteb. Důraz je kladen na prosbu za odpuštění hříchů zesnulého. Zajistíme, aby všechny tyto prvky byly správně začleněny do obřadu."
  },
  {
    otazka: "Jaké oblečení je vhodné pro zesnulého při pravoslavném pohřbu?",
    odpoved: "V pravoslavné tradici je zesnulý obvykle oblečen do svého nejlepšího oblečení. Někdy se používá i speciální pohřební oděv. Pro muže je to často oblek, pro ženy šaty. Důležité je, aby oblečení bylo důstojné a respektovalo pravoslavné zvyklosti. Poradíme vám s výběrem vhodného oblečení pro zesnulého."
  },
  {
    otazka: "Jak probíhá pravoslavná panychida?",
    odpoved: "Panychida je krátká bohoslužba za zesnulé v pravoslavné tradici. Může se konat večer před pohřbem nebo v jiné dny. Zahrnuje modlitby, žalmy a prosby za odpočinutí duše zesnulého. Můžeme pro vás zajistit konání panychidy jako součást pohřebních obřadů."
  },
];

const NoWrap = styled.span`
  white-space: nowrap;
`;

const ObradInner = styled.div`
  background: #FBFBFA;
  padding-bottom: 1px;


`;






const PravoslavnyPohreb = ({ data }) => {
  return (
    <Layout
      subMenuLong
      forcePohrbyMenu
      customCtaLink="https://forms.gle/FaqGHS6reoURkZJw5"
      subMenuItems={[
        {
          name: "Pohřební služba",
          url: "/pohrebni-sluzba/",
        },
        {
          name: "Kde fungujeme?",
          url: "/pobocky/",
        },
        {
          name: "Parte",
          url: "/parte/",
        },
        {
          name: "Kremace bez obřadu",
          url: "/kremace-bez-obradu/",
        },
        {
          name: "Pohřeb s obřadem",
          url: "/pohreb-s-obradem/",
        },
        {
          name: "Netradiční pohřeb",
          url: "/netradicni-pohreb/",
        },
        {
          name: "Pohřeb miminka",
          url: "/pohreb-miminka/",
        },
      ]}
    >
      <SEO
        title="Důstojný pravoslavný pohřeb od 18 900 Kč | Goodbye.cz"
        customTitle
        description="Pomůžeme vám zařídit pravoslavný pohřeb podle tradic. Obřad v kostele, květiny, ikony. Jedna transparentní cena. Vše sjednáte online."
        image={"/obradOg.png"}
      />

      <StructuredHodnoceni />

      <ObradInner>
        
        

        <Jumbotron
          h1="Pravoslavný pohřeb"
          p={<>Rozlučte se se svým blízkým podle pravoslavných tradic. Pomůžeme vám zajistit obřad v kostele nebo pohřeb do země podle vašich přání a zvyklostí vaší víry.<br/><br/>

          Попрощайтеся з близькою людиною за православними традиціями. Ми допоможемо організувати обряд у церкві або поховання в землю відповідно до ваших побажань та звичаїв вашої віри. Наші послуги включають повну організацію православного похорону, забезпечення всіх необхідних атрибутів та підтримку на кожному етапі.</>}
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={<>Jsem vděčná Goodbye za jejich <span>citlivý přístup a respekt k našim pravoslavným zvyklostem</span>. Obřad byl krásný a důstojný, přesně jak jsme si přáli. Velmi doporučuji.</>}
          recenzeImgFixed
          recenzeImg={data.recenzeSample.childImageSharp.fixed}
          recenzeImgSmall={data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={"Olga K."}
          zakaznikKdo={"Zákaznice"}
          isDark
          breadscrum={
            <Breadscrum
              visible
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: "Pravoslavný pohřeb", url: "/pravoslavny-pohreb/"}
              ]}
            />
          }
          testingCtaUrl="https://forms.gle/FaqGHS6reoURkZJw5"
        />


        <UvodniText
          h2="Chápeme význam pravoslavných tradic při posledním rozloučení"
          p="Jsme tu, abychom vás provedli všemi potřebnými kroky při organizaci pravoslavného pohřbu. Ať už pocházíte z Ukrajiny, Ruska, zemí Balkánu nebo jiných oblastí s pravoslavnou tradicí, pomůžeme vám naplánovat obřad v souladu s vašimi zvyklostmi. Zajistíme vše od ikon a svíček po kněze a modlitby, respektujíce specifika pravoslaví v různých zemích. Vše spolu vyřešíme přes internet a z bezpečí vašeho domova."
          img={data.pohrebUvod.childImageSharp.fixed}
          imgSmall={data.pohrebUvodSmall.childImageSharp.fixed}
        />



        <Features isPruh />



        <NaceneniV2
          h2="V ceně je zahrnuto vše potřebné pro pravoslavný obřad"
          p="Způsob rozloučení přizpůsobíme vašim tradicím. My se postaráme o vše ostatní za předem stanovenou cenu."
          checklist={["Kompletní sjednání po internetu", "Přeprava vašeho blízkého", "Tvorba parte", "Pravoslavný obřad v kostele nebo na hřbitově", "Zajištění kněze, ikon, svíček a květinové výzdoby", "Rakev vhodná pro pravoslavný obřad", "Pohřeb do země včetně poplatků"]}
          price="22 990 Kč"
          extrasText="Nabízíme i možnost připlatit si za další tradiční doplňky"
          extras={[
            {
              h4: "Výběr jiné rakve",
              p: "Nabízíme i několik dalších dřevěných variant vhodných pro pravoslavný obřad.",
              price: "2 900 - 3 900 Kč",
              img: data.jinaRakev.childImageSharp.fixed,
            },
            {
              h4: "Rozšířená květinová výzdoba",
              p: "Můžeme zajistit bohatší květinovou výzdobu podle pravoslavných zvyklostí.",
              price: "1 990 - 4 500 Kč",
              img: data.jineKytky.childImageSharp.fixed,
            },
          ]}
          testingCtaUrl="https://forms.gle/FaqGHS6reoURkZJw5"
        />



        <Postup
          isPruh

          fotka1={data.postup1.childImageSharp.fluid}
          heading1={"Nezávazně si zavoláme a probereme vaši situaci"}
          text1={"Chápeme, že ztráta blízkého je těžká situace. Jsme připraveni vám pomoci s organizací pravoslavného pohřbu podle vašich tradic. Můžete se nás zeptat na cokoliv ohledně průběhu obřadu nebo pohřbu do země. Jsme vám k dispozici každý den, ve dne v noci."}

          fotka2={data.postup2.childImageSharp.fluid}
          heading2={"Pravoslavný pohřeb s námi naplánujete z bezpečí domova"}
          text2={<>Na plánování budete mít dostatek času. Vše s vámi vyřešíme po telefonu a on-line (výběr ikon, svíček, rakve, parte a podobně). Poradíme vám s tradičními prvky pravoslavného obřadu. Zvládnete to tak bez zbytečného tlaku a stresu. Zbytek můžete nechat na nás.</>}

          fotka3={data.postup3.childImageSharp.fluid}
          heading3={"O vše ostatní se už postaráme my"}
          text3={"Postaráme se o všechny formality, přípravu zesnulého, zajištění kněze a průběh obřadu podle pravoslavných tradic. Zajistíme, aby poslední rozloučení proběhlo důstojně a v souladu s vaší vírou."}
        />
        
        <RecenzePohreb />

        <RegionsV2 />

        <Press />

        <SeVsimPomuzeme 
          customText="Chápeme, že ztráta blízkého je těžká situace, zvláště když chcete dodržet pravoslavné tradice v ČR. Zavolejte naší pohřební službě a jsme připraveni vám okamžitě pomoci. Postaráme se o vše potřebné pro důstojný pravoslavný obřad a poslední rozloučení v souladu s vaší vírou."
          customCtaLink="https://forms.gle/FaqGHS6reoURkZJw5"
        />

        <FAQ data={faqData}/>

      </ObradInner>
    </Layout>
  );
}

export const query = graphql`
  query {


    big: file(relativePath: { eq: "pravoslavny.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    small: file(relativePath: { eq: "pravoslavny.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSample: file(relativePath: { eq: "zakaznice4.png" }) {
      childImageSharp {
        fixed(width: 340) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvod: file(relativePath: { eq: "pravoslavnyBlob.png" }) {
      childImageSharp {
        fixed(width: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    pohrebUvodSmall: file(relativePath: { eq: "pravoslavnyBlob.png" }) {
      childImageSharp {
        fixed(width: 200) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    postup1: file(relativePath: { eq: "postup1.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup2: file(relativePath: { eq: "postup2.png" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    postup3: file(relativePath: { eq: "pravoslavny2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 420) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    recenzeSampleSmall: file(relativePath: { eq: "zakaznice4Small.png" }) {
      childImageSharp {
        fixed(width: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }








    jinaRakev: file(relativePath: { eq: "jinaRakev.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jineKytky: file(relativePath: { eq: "jineKytky.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    jinaUrna: file(relativePath: { eq: "jinaUrna.jpg" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekKvetiny: file(relativePath: { eq: "doplnekKvetiny.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekRakve: file(relativePath: { eq: "doplnekRakve.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    doplnekUrny: file(relativePath: { eq: "doplnekUrny.png" }) {
      childImageSharp {
        fixed(width: 155) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }

    naceneni: file(relativePath: { eq: "naceneniPozadi.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

  }
`;

export default PravoslavnyPohreb;


